import './HeaderSecond.css';

import drop_down from 'assets/images/drop_down.svg';
import asman from 'assets/images/logo.png';
import logoOne from 'assets/images/logoIconOne.png';
import logoTwo from 'assets/images/logoIconTwo.png';
import refetch from 'assets/images/refetch.svg';
import star_icon from 'assets/images/star.svg';
import fourth from 'assets/toys/4.png';
import { useAppSelector } from 'hooks/redux';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { AbiItem } from 'web3-utils';
import { web3Provider as web3 } from 'web3Provider';
const tokenContract = process.env.REACT_APP_SMART_CONTRACT;

let balanceOfABI: AbiItem[] = [
	{
		constant: true,
		inputs: [
			{
				name: '_owner',
				type: 'address',
			},
		],
		name: 'balanceOf',
		outputs: [
			{
				name: 'balance',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
];

interface Keys {
	myKey: string;
}

const HeaderSecond = (props: Keys) => {
	// const { address, token } = useAppSelector((state) => state.web3.user.infoUser);
	const { address } = useAccount();
	const myKey = props.myKey;
	const [sum, setSum] = useState<string>('0');
	const contract = new web3.eth.Contract(balanceOfABI, tokenContract);
	// console.log(contract);
	const getTokenBalance = async () => {
		const result = await contract.methods.balanceOf(address).call();

		const formattedResult = web3.utils.fromWei(result);
		setSum(formattedResult);
	};
	useEffect(() => {
		getTokenBalance();
	}, [address]);

	return (
		<section id="headerSecond">
			<div className="headerSecond__start">
				{myKey === 'leader' ? (
					<p className="main__title">Рефералы</p>
				) : myKey === 'info' ? (
					<p className="main__title">Информация</p>
				) : (
					<p className="main__title">Обменик (BUSD/ASMAN)</p>
				)}
			</div>
			<div className="headerSecond__end">
				<div className="headerSecond__end__wallet">
					<div className="headerSecond__end__wallet-2">
						<img src={asman} alt="" />
						<p> {sum} ASMAN</p>
					</div>
				</div>
				<div className="headerSecond__end-info">
					<p className="header__second-text">{address}</p>
					<img src={drop_down} alt="" />
				</div>
			</div>
		</section>
	);
};

export default HeaderSecond;
