/* eslint-disable @typescript-eslint/indent */
/* eslint-disable quotes */
import "./App.css";

import ButtonLogin from "components/ButtonLogin/ButtonLogin";
import { useCheckAccountChanged } from "hooks/useCheckAccountChanged";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { SidebarContext } from "utils/SidebarContext";
import { useAccount } from "wagmi";
import { AbiItem } from "web3-utils";
import { web3Provider as web3 } from "web3Provider";

import { useAppSelector } from "./hooks/redux";
import Info from "./pages/Info/Info";
import LeaderboardPage from "./pages/LeaderboardPage/LeaderboardPage";
import PredictionPage from "./pages/PredictionPage/PredictionPage";

type Body = { wallet?: string };
type Balance = { balance?: string };
let balanceOfABI: AbiItem[] = [
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

const App = () => {
  const [toggle, setToggle] = useState<boolean>(false);
  const { checkChanged } = useCheckAccountChanged();
  const { address } = useAccount();

  const toggleFunc = () => {
    setToggle((prev) => !prev);
  };

  const contract = new web3.eth.Contract(balanceOfABI, process.env.REACT_APP_SMART_CONTRACT);
  const getTokenBalance = async () => {
    if (!address) return;
    const result = await contract.methods.balanceOf(address).call();

    const formattedResult = web3.utils.fromWei(result);
    postSum(formattedResult);

  };

  const authReg = async () => {
    if (!address) return;
    let body: Body = {
      wallet: address,
    };
    const res = await fetch(`${process.env.REACT_APP_BACK}auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const req = await res.json();

    sessionStorage.setItem("token", req);
    createRefererLink(req);
  };
  useEffect(() => {
    checkChanged();
    authReg();
  }, [address]);

  useEffect(() => {
    getTokenBalance();
  });

  const createRefererLink = async (tokenq:any) => {

    if (!tokenq) return;
    const currentURL = window.location.href;
    const params = currentURL.match(/referer=([^&]+)/);
    if (!params) return;
    let obj = {
      referer: "",
      binary: "",
    };
    if (params) {
      const referer = params[1];

      // Extract the left parameters and everything after it
      const leftParams = referer.match(/left(.*)/);
      if (leftParams) {
        console.log("Left params:", leftParams[1]);
        obj.referer = leftParams[1];
        obj.binary = "left";
      }

      // Extract the right parameters and everything after it
      const rightParams = referer.match(/right(.*)/);
      if (rightParams) {
        console.log("Right params:", rightParams[1]);
        obj.referer = rightParams[1];
        obj.binary = "right";
      }
      const res = await fetch(`${process.env.REACT_APP_BACK}referer`, {
        method: "POST",
        headers: {
          Authorization: tokenq,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      const req = await res.json();
    } else {
      console.log("No referer parameter found in the URL.");
    }
  };

  const postSum = async (sum: string) => {
    let tokenq = sessionStorage.getItem("token");
    if (!tokenq) return;
    let body: Balance = {
      balance: sum,
    };
    const res = await fetch(`${process.env.REACT_APP_BACK}updatebalance`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenq,
      },
      body: JSON.stringify(body),
    });
    const req = await res.json();
  };

  return (
    <>
      <SidebarContext.Provider value={{ toggle, toggleFunc }}>
        {address ? (
          <Routes>
            <Route path={"/"} element={<PredictionPage />} />
            <Route path={"/referals"} element={<LeaderboardPage />} />
            <Route path={"/info"} element={<Info />} />
          </Routes>
        ) : (
          <Routes>{<Route path={"*"} element={<ButtonLogin />} />}</Routes>
        )}
      </SidebarContext.Provider>
    </>
  );
};

export default App;
