import './MainLeader.css';

import Loader from 'components/Loader/Loader';
import { useContext, useEffect, useState } from 'react';
import { SidebarContext } from 'utils/SidebarContext';
import { useAccount } from 'wagmi';
import { AbiItem } from 'web3-utils';
import { web3Provider as web3 } from 'web3Provider';

import HeaderSecond from '../HeaderSecond/HeaderSecond';
import Winner from '../Winner/Winner';
import WinnerInfoWrap from '../WinnerInfoWrap/WinnerInfoWrap';
let transfer: AbiItem[] = [
	// transfer
	{
		constant: false,
		inputs: [
			{
				name: '_to',
				type: 'address',
			},
			{
				name: '_value',
				type: 'uint256',
			},
		],
		name: 'transfer',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		type: 'function',
	},
];

const MainLeader = () => {
	const { toggle } = useContext<any>(SidebarContext);
	const [side, setSide] = useState('left');
	const { address } = useAccount();
	const [load, setLoad] = useState(false);
	const [status, setStatus] = useState();
	const [summary, setSummary] = useState({
		left: 0,
		right: 0,
	});

	const [price, setPrice] = useState<number>(1);
	useEffect(() => {
		getPrice();
		getStatus();
	}, []);

	const getPrice = async () => {
		let tokenq = sessionStorage.getItem('token');
		if (!tokenq) return;

		const res = await fetch(`${process.env.REACT_APP_BACK}price`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: tokenq,
			},
		});
		const req = await res.json();
		let sum = Number(req);
		console.log(sum);
		setPrice(sum);
	};
	const getStatus = async () => {
		let tokenq = sessionStorage.getItem('token');
		if (!tokenq) return;
		const res = await fetch(`${process.env.REACT_APP_BACK}getstatus`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: tokenq,
			},
		});
		const req = await res.json();
		setStatus(req);
	};

	const activated_status = async () => {
		let tokenq = sessionStorage.getItem('token');
		if (!tokenq) return;
		const res = await fetch(`${process.env.REACT_APP_BACK}activated`, {
			method: 'POSt',
			headers: {
				'Content-Type': 'application/json',
				Authorization: tokenq,
			},
		});
		const req = await res.json();
		getStatus();
	};

	const sendRefers = async () => {
		let tokenq = sessionStorage.getItem('token');
		if (!tokenq) return;
		const res = await fetch(`${process.env.REACT_APP_BACK}listreferers`, {
			method: 'POSt',
			headers: {
				'Content-Type': 'application/json',
				Authorization: tokenq,
			},
		});
		const req = await res.json();
	};

	const transactionAsman = async () => {
		const Private_Key = process.env.REACT_APP_PRIVATE_KEY;
		let contractABI = transfer;
		let addressAsman = process.env.REACT_APP_SMART_CONTRACT;

		let count = 40 / price;
		const contractInstance = new web3.eth.Contract(contractABI, addressAsman);
		if (Private_Key) {
			const tx = await web3.eth.accounts.signTransaction(
				{
					from: process.env.REACT_APP_MAT_WALLET,
					// @ts-ignore
					gas: '200000',
					// @ts-ignore
					to: contractInstance._address,
					data: contractInstance.methods
						.transfer(address, web3.utils.toWei(count.toString()))
						.encodeABI(),
				},
				Private_Key,
			);

			web3.eth
			// @ts-ignore
				.sendSignedTransaction(tx.rawTransaction)
				.then((res) => {
					console.log('res', res);
					transaction1();
				})
				.catch((err) => {
					console.log('err', err);
					alert('OOps error, try again later');
					setLoad(false);
				});
		}
	};

	const transaction1 = async () => {
		const sum = 13;
		const Private_Key = process.env.REACT_APP_PRIVATE_KEY;
		let contractABI = transfer;
		let addressBUSD = '0xe9e7cea3dedca5984780bafc599bd69add087d56';

		const contractInstance = new web3.eth.Contract(contractABI, addressBUSD);
		if (Private_Key) {
			const tx = await web3.eth.accounts.signTransaction(
				{
					from: process.env.REACT_APP_MAT_WALLET,
					// @ts-ignore

					gas: web3.utils.toHex(210000),
					gasPrice: web3.utils.toHex(10e9),
					// @ts-ignore
					to: contractInstance._address,
					data: contractInstance.methods
						.transfer(
							'0xb8f9505cC43BF1F20945E2F21d40c36a51c84322',
							web3.utils.toWei(sum.toString()),
						)
						.encodeABI(),
				},
				Private_Key,
			);

			web3.eth
			// @ts-ignore
				.sendSignedTransaction(tx.rawTransaction)
				.then((res) => {
					console.log('res', res);
					transaction2();
				})
				.catch((err) => {
					console.log('err', err);
					alert('OOps error, try again later');
					setLoad(false);
				});
		}
	};
	const transaction2 = async () => {
		const sum = 46;
		const Private_Key = process.env.REACT_APP_PRIVATE_KEY;
		let contractABI = transfer;
		let addressBUSD = '0xe9e7cea3dedca5984780bafc599bd69add087d56';

		const contractInstance = new web3.eth.Contract(contractABI, addressBUSD);
		if (Private_Key) {
			const tx = await web3.eth.accounts.signTransaction(
				{
					from: process.env.REACT_APP_MAT_WALLET,
					// @ts-ignore

					gas: web3.utils.toHex(210000),
					gasPrice: web3.utils.toHex(10e9),
					// @ts-ignore
					to: contractInstance._address,
					data: contractInstance.methods
						.transfer(
							'0xaC4d6B06eA8e1f0f3cf58D397Aa44A366207EF74',
							web3.utils.toWei(sum.toString()),
						)
						.encodeABI(),
				},
				Private_Key,
			);

			web3.eth
			// @ts-ignore
				.sendSignedTransaction(tx.rawTransaction)
				.then((res) => {
					console.log('res', res);
					setLoad(false);
				})
				.catch((err) => {
					console.log('err', err);
					alert('OOps error, try again later');
					setLoad(false);
				});
		}
	};
	const buy = async (sum: any) => {
		let tokenq = sessionStorage.getItem('token');
		if (!tokenq) return;

		const res = await fetch(`${process.env.REACT_APP_BACK}buy`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: tokenq,
			},
			body: JSON.stringify({ sum }),
		});
		const req = await res.json();
		setLoad(false);
	};
	const activated = async () => {
		setLoad(true);
		let contractABI = transfer;
		let addressBUSD = '0xe9e7cea3dedca5984780bafc599bd69add087d56';
		// IT'S OTHER CONTRACT
		// const web3 = new web3(web3.currentProvider);
		const contractInstance = new web3.eth.Contract(contractABI, addressBUSD);
		const tx = {
			from: address,
			// @ts-ignore
			to: contractInstance._address,

			data: contractInstance.methods
				.transfer(process.env.REACT_APP_MAT_WALLET, web3.utils.toWei('110'))
				.encodeABI(),
		};
		web3.eth
			.sendTransaction(tx)
			.then((res) => {
				console.log('res', res);
				activated_status();
				buy(40);
				sendRefers();
				transactionAsman();

			})
			.catch((err) => {
				console.log('err', err);
				alert('OOps error, try again later');
				setLoad(false);
			});
	};

	if (load) return <Loader />;

	return (
		<main className={true ? 'main__grow' : ''}>
			<div className="main__parent">
				<HeaderSecond myKey={'leader'} />

				{status == null || status == undefined ? null : status ? (
					<>
						<p style={{ marginBottom: '16px', color: '#ffffff' }}>
              Команды:{' '}
							<span
								style={
									side === 'left'
										? {
											color: '#ffffff',
										}
										: {
											color: 'gray',
											cursor: 'pointer',
											textDecoration: 'underline',
										}
								}
								onClick={() => {
									setSide('left');
								}}
							>
                Левая
							</span>
              /
							<span
								style={
									side !== 'left'
										? {
											color: '#ffffff',
										}
										: {
											color: 'gray',
											cursor: 'pointer',
											textDecoration: 'underline',
										}
								}
								onClick={() => {
									setSide('right');
								}}
							>
                Правая
							</span>
						</p>

						<Winner side={side} summary={summary} />

						<WinnerInfoWrap side={side} setSummary={setSummary} />
					</>
				) : (
					<div className="pay-activated">
						<p>Для того чтоб начать работы с рефералами в приложении Asman</p>
						<p>Вам нужно активировать данную возможность</p>
						<p>стоимость активации 110 BUSD</p>
						<button onClick={activated}>Активировать</button>
					</div>
				)}
			</div>
		</main>
	);
};

export default MainLeader;
