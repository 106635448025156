/* eslint-disable max-len */
import './Profile.css';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import arrowDown from 'assets/images/arrow-down.svg';
import balance from 'assets/images/balanceIcon.svg';
import bars from 'assets/images/Bars.svg';
import BarsTotalWin from 'assets/images/BarsTotalWin.svg';
import game from 'assets/images/game.svg';
import help from 'assets/images/help.svg';
import logo from 'assets/images/logo.png';
import magicWallet from 'assets/images/magicWallet.svg';
import magicWallet2 from 'assets/images/magicWallet2.svg';
import metamask from 'assets/images/metamask_icon2.svg';
import totalWin from 'assets/images/totalWin.svg';
import Loader from 'components/Loader/Loader';
import { useAppSelector } from 'hooks/redux';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { AbiItem } from 'web3-utils';
import { web3Provider as web3 } from 'web3Provider';

import SwitchWalletModal from '../SwitchWalletModal/SwitchWalletModal';
import TopUpBalanceModal from '../TopUpBalanceModal/TopUpBalanceModal';
let transfer: AbiItem[] = [
	// transfer
	{
		constant: false,
		inputs: [
			{
				name: '_to',
				type: 'address',
			},
			{
				name: '_value',
				type: 'uint256',
			},
		],
		name: 'transfer',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		type: 'function',
	},
];
const Profile = () => {
	const [switchWallet, setSwitchWallet] = useState<boolean>(false);
	const { address } = useAccount();
	const [upBalance, setUpBalance] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [amount, setAmount] = useState<string>('');
	const [withdraw, setWithdraw] = useState<number>(0);
	const [price, setPrice] = useState<number>(1);
	const open = Boolean(anchorEl);
	const [load, setLoad] = useState(false);
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		getPrice();
		getMe();
	}, []);

	const getPrice = async () => {
		let tokenq = sessionStorage.getItem('token');
		if (!tokenq) return;

		const res = await fetch(`${process.env.REACT_APP_BACK}price`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: tokenq,
			},
		});
		const req = await res.json();
		let sum = Number(req);
		console.log(sum);
		setPrice(sum);
	};

	const sendAsman = async () => {
		const Private_Key = process.env.REACT_APP_PRIVATE_KEY;
		let contractABI = transfer;
		let addressAsman = process.env.REACT_APP_SMART_CONTRACT;
		const contractInstance = new web3.eth.Contract(contractABI, addressAsman);
		if (Private_Key) {
			const tx = await web3.eth.accounts.signTransaction(
				{
					from: process.env.REACT_APP_MAT_WALLET,
					// @ts-ignore
					gas: '200000',
					// @ts-ignore
					to: contractInstance._address,
					data: contractInstance.methods
						.transfer(address, web3.utils.toWei(amount.toString()))
						.encodeABI(),
				},
				Private_Key,
			);

			web3.eth
			// @ts-ignore
				.sendSignedTransaction(tx.rawTransaction)
				.then((res) => {
					console.log('res', res);

					alert('Transaction successfull');
					setLoad(false);
				})
				.catch((err) => {
					console.log('err', err);
					alert('OOps error, try again later');
					setLoad(false);
				});
		}
	};

	const getMe = async () => {
		let tokenq = sessionStorage.getItem('token');
		if (!tokenq) return;
		const resp = await fetch(`${process.env.REACT_APP_BACK}me`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: tokenq,
			},
		});
		const req = await resp.json();

		setWithdraw(req.withdraw ? req.withdraw : 0);
	};

	const buy = async (sum: any) => {
		let tokenq = sessionStorage.getItem('token');
		if (!tokenq) return;

		const res = await fetch(`${process.env.REACT_APP_BACK}buy`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: tokenq,
			},
			body: JSON.stringify({ sum }),
		});
		const req = await res.json();
		sendAsman();
	};

	const send = () => {
		if (price) {
			setLoad(true);
			let contractABI = transfer;
			let addressBUSD = '0xe9e7cea3dedca5984780bafc599bd69add087d56';
			// 0xb8f9505cC43BF1F20945E2F21d40c36a51c84322 bonuses
			let sumq = +amount * price;
			console.log(sumq);
			console.log(amount);
			console.log(price);
			const contractInstance = new web3.eth.Contract(contractABI, addressBUSD);
			const tx = {
				from: address,
				// @ts-ignore
				to: contractInstance._address,

				data: contractInstance.methods
					.transfer(process.env.REACT_APP_ASMAN_WALLET, web3.utils.toWei(sumq.toString()))
					.encodeABI(),
			};
			web3.eth
				.sendTransaction(tx)
				.then((res) => {
					console.log('res', res);
					buy(sumq);
				})
				.catch((err) => {
					console.log('err', err);
					alert('OOps error, try again later');
					setLoad(false);
				});
		}
	};

	const withdrawAll = async () => {
		let tokenq = sessionStorage.getItem('token');
		if (!tokenq) return;
		const resp = await fetch(`${process.env.REACT_APP_BACK}getmoney`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: tokenq,
			},
		});
		const req = await resp.json();
		setWithdraw(req.withdraw);
		setLoad(false);
		alert('Busd успешно отправились на ваш кошелек');
	};

	const transactionWithdrawAll = async () => {
		setLoad(true);
		if (withdraw < 1) {
			alert('Ваш баланс меньше 1 busd');
			setLoad(false);
		} else {
			const sum = withdraw;
			const Private_Key = process.env.REACT_APP_PRIVATE_KEY;
			let contractABI = transfer;
			let addressBUSD = '0xe9e7cea3dedca5984780bafc599bd69add087d56';

			const contractInstance = new web3.eth.Contract(contractABI, addressBUSD);
			if (Private_Key) {
				const tx = await web3.eth.accounts.signTransaction(
					{
						from: process.env.REACT_APP_MAT_WALLET,
						// @ts-ignore

						gas: web3.utils.toHex(210000),
						gasPrice: web3.utils.toHex(10e9),
						// @ts-ignore
						to: contractInstance._address,
						data: contractInstance.methods
							.transfer(address, web3.utils.toWei(sum.toString()))
							.encodeABI(),
					},
					Private_Key,
				);

				web3.eth
				// @ts-ignore
					.sendSignedTransaction(tx.rawTransaction)
					.then((res) => {
						console.log('res', res);
						withdrawAll();
					})
					.catch((err) => {
						console.log('err', err);
						alert('OOps error, try again later');
						setLoad(false);
					});
			}
		}
	};

	if (load) return <Loader />;

	return (
		<>
			<section id="profile" style={{ marginBottom: '12px' }}>
				<div className="profile__transfer" style={{ width: '100%' }}>
					<p>Вывод</p>
					<p className="amount__title">Кол-во заработаных BUSD - {withdraw}</p>

					<button onClick={transactionWithdrawAll} className="transfer_btn">
            Вывести
					</button>
				</div>
			</section>

			<section id="profile">
				<div className="profile__transfer">
					<p>Ваш аккаунт</p>
					<div className="profile__wallet">
						<div className="wallet__img">
							<img src={magicWallet} alt="wallet" />
							<div className="wallet__title">
								<p>Ваш кошелек с BUSD</p>
								<p>
									{address
										? `${address.substring(0, 6)}...${address.substring(address.length - 4)}`
										: ''}
								</p>
							</div>
						</div>
					</div>

					<div className="profile__transfer__arrow">
						<img src={arrowDown} alt="arrowDown" />
					</div>

					<p>Asman Аккаунт</p>
					<div className="profile__wallet">
						<div className="wallet__img">
							<img src={metamask} alt="magicWallet" />
							<div className="wallet__title">
								<p>Metamask</p>

								<p>
									{process.env.REACT_APP_MAT_WALLET
										? `${process.env.REACT_APP_MAT_WALLET.substring(
											0,
											6,
										)}...${process.env.REACT_APP_MAT_WALLET.substring(
											process.env.REACT_APP_MAT_WALLET.length - 4,
										)}`
										: ''}
								</p>
							</div>
						</div>
					</div>

					<p className="amount__title">Кол-во Asman для приобретения</p>

					<div className="amount__input">
						<input
							placeholder="0.00"
							type="number"
							onChange={(e) => {
								setAmount(e.target.value);
							}}
						/>
						<div>
							<img style={{ width: '24px' }} src={logo} alt="qnt" />
							<p>Asman</p>
						</div>
					</div>
					<button onClick={send} className="transfer_btn">
            Купить
					</button>
				</div>
			</section>
		</>
	);
};

export default Profile;
