import './Main.css';

import { useContext } from 'react';
import { SidebarContext } from 'utils/SidebarContext';

import CardSlider from '../CardSlider/CardSlider';
import Chart from '../Chart/Chart';
import HeaderSecond from '../HeaderSecond/HeaderSecond';
import Profile from '../Profile/Profile';

const Main = () => {
	const { toggle } = useContext<any>(SidebarContext);
	return (
		<main className={true ? 'main__grow' : ''}>
			<div className="main__parent">
				<HeaderSecond myKey={'prediction'} />
				<Profile />
				{/* <CardSlider/>
				<
				<Chart/> */}
			</div>
		</main>
	);
};

export default Main;
