// @ts-ignore
import { CircularProgressBar } from '@tomickigrzegorz/react-circular-progress-bar';
import { useEffect, useState } from 'react';

import car from '../../assets/images/bonuses/car.png';
import iphone from '../../assets/images/bonuses/iphone.png';
import ktj from '../../assets/images/bonuses/ktj.png';
import kv from '../../assets/images/bonuses/kv.png';
import ticket from '../../assets/images/bonuses/ticket.png';

type Props = {
	summary: any;
};

const ProgressBar = ({ summary }: Props) => {
	const [config, setConfig] = useState<any>();
	const arr = [5000, 18000, 80000, 380000, 1000000];
	console.log('summary', summary);
	const [tNum, setTNum] = useState<any>(0);
	// useEffect(() => {
	//   summary.left >= summary.right ? setSum(summary.right) : setSum(summary.left);
	// }, [summary]);

	useEffect(() => {
		const sum = summary.left >= summary.right ? summary.right : summary.left;
		const num = arr.find((el) => el >= sum);
		setTNum(num);
		if (num !== undefined && sum >= 0) {
			const p = (sum / num) * 100;

			setConfig({
				percent: p.toFixed(0) || 0,
				colorSlice: '#6CA540',
				colorCircle: '#E7E9EE',
				fontWeight: 50,
				number: true,
				fontSize: '0.6em',
				textPosition: '1.7em',
			});
		}
	}, [summary]);

	return (
		<div
			style={{
				position: 'relative',
			}}
		>
			{config && (
				<CircularProgressBar key={config.percent} {...config}>
					<img
						src={
							tNum <= arr[0]
								? ticket
								: tNum <= arr[1]
									? iphone
									: tNum <= arr[2]
										? car
										: tNum <= arr[3]
											? kv
											: ktj
						}
						style={{
							width: '30%',
							borderRadius: '50%',
							position: 'absolute',
							top: '33%',
							left: '50%',
							transform: 'translate(-50%,-50%)',
						}}
						alt=""
					/>
					<div style={{ textAlign: 'center', padding: '0 35px' }}>
						{tNum <= arr[0]
							? 'Две путевки'
							: tNum <= arr[1]
								? 'Новый айфон'
								: tNum <= arr[2]
									? 'Автомобиль'
									: tNum <= arr[3]
										? 'Квартира'
										: 'Коттедж'}
						<br />
					</div>
				</CircularProgressBar>
			)}
			<p
				style={{
					textAlign: 'center',
					marginTop: '25px',
				}}
			>
        Общий оборот:{' '}
				{summary.left >= summary.right ? summary.right.toFixed(2) : summary.left.toFixed(2)}$
			</p>
			<p
				style={{
					textAlign: 'center',
					marginTop: '10px',
				}}
			>
        Обороты сторон: <br />
        Левая {summary.left}$ <br />
        Правая {summary.right}$
			</p>
		</div>
	);
};

export default ProgressBar;
