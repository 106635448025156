import './Winner.css';

import filter from 'assets/images/filter-list.svg';
import search from 'assets/images/searchIcon.svg';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useEffect, useState } from 'react';
import { SET_SEARCH, SET_SORT } from 'redux/slicers/leaderSlice';
import { useAccount } from 'wagmi';

type Props = {
	side: string;
	summary: any;
};

const Winner = ({ side, summary }: Props) => {
	const [username, setUserName] = useState('');
	const dispatch = useAppDispatch();
	// const { address, token } = useAppSelector((state) => state.web3.user.infoUser);
	// console.log(token);
	const { address } = useAccount();
	useEffect(() => {
		getMe();
	}, []);
	const getMe = async () => {
		let tokenq = sessionStorage.getItem('token');
		if (!tokenq) return;
		const resp = await fetch(`${process.env.REACT_APP_BACK}me`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: tokenq,
			},
		});
		const req = await resp.json();
		console.log(req);
		setUserName(req.username ? req.username : '');
	};
	const copy = () => {
		var textField = document.createElement('textarea');
		textField.innerText = `https://trade.asman.io?referer=${side}${address}`;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand('copy');
		textField.remove();
		alert('Ссылка скопирована!');
	};

	const handleChange = (e: any) => {
		if (e.target.value.length >= 3) {
			dispatch(SET_SEARCH(e.target.value));
		} else if (e.target.value.length === 0) {
			dispatch(SET_SEARCH(''));
		}
	};

	const changeName = async () => {
		let tokenq = sessionStorage.getItem('token');
		if (!tokenq) return;
		const res = await fetch(`${process.env.REACT_APP_BACK}changeusername`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: tokenq,
			},
			body: JSON.stringify({ username }),
		});
		const req = await res.json();
		alert('Ваше имя изменено');
	};
	return (
		<section id="winner">
			<div className="winner__filter">
				<input
					placeholder="ваше имя"
					value={username}
					onChange={(e) => setUserName(e.target.value)}
				/>
				<button onClick={changeName}>Сохранить</button>
			</div>
			<div onClick={copy} className="winner__filter">
				<img src={filter} alt="filter" />
				<p>Копировать ссылку для {side === 'left' ? 'левой' : 'правой'} команды</p>
			</div>
			<div className="winner__search">
				<ProgressBar summary={summary} />
			</div>
		</section>
	);
};

export default Winner;
