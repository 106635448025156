import { SET_CONNECTED, SET_INFO_USER } from 'redux/slicers/Web3Slicer';
import { useLazyAuthVerifyQuery, useLazyGetAuthNonseQuery } from 'services/query';

import { web3Provider as web3 } from './../web3Provider';
import { useAppDispatch } from './redux';

export const useWeb3SigIn = () => {
	const [trigger] = useLazyAuthVerifyQuery();
	const [trigger2] = useLazyGetAuthNonseQuery();
	const dispatch = useAppDispatch();

	const sigIn = async (accounts: string) => {
		// @ts-ignore
		web3.eth.personal.sign(web3.utils.toHex(), accounts, null, (err, sign) => {
			trigger({ address: accounts, token: sign }).then((verify) => {
				dispatch(SET_INFO_USER({ address: accounts, token: '123' }));
				dispatch(SET_CONNECTED(true));
			});
		});
	};

	return {
		sigIn,
	};
};
