import './Footer.css';

const Footer = () => {
	return (
		<footer>
			<div className="container">
				<div className="footer__nav">
					<div className="footer__icons footer__box"></div>
					<p className="footer__copy footer__box">© 2023. All Rights Reserved. </p>
					<div className="footers__terms footer__box"></div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
