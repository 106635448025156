import './WinnerInfoWrap.css';

import { useAppSelector } from 'hooks/redux';
import { useEffect, useState } from 'react';
import { useLazyGetLeaderboardQuery } from 'services/query';
import { useAccount } from 'wagmi';
import Web3 from 'web3';

import WinnerInfo from '../WinnerInfo/WinnerInfo';

type Prop = {
	side: string;
	setSummary: any;
};

const WinnerInfoWrap = ({ side, setSummary }: Prop) => {
	// const { address, token } = useAppSelector((state) => state.web3.user.infoUser);
	const { address } = useAccount();
	const { sort, search } = useAppSelector((state) => state.leader);

	const [data, setData] = useState<any>({});

	const getData = async () => {
		let tokenq = sessionStorage.getItem('token');
		if (!tokenq) return;
		const resp = await fetch(`${process.env.REACT_APP_BACK}referals`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: tokenq,
			},
		});
		const req = await resp.json();
		console.log(req);
		setData(req);
		setSummary({
			left: req.leftSum,
			right: req.rightSum,
		});
	};

	useEffect(() => {
		getData();
	}, []);

	// const renderRest =  obje[]arr.map((el, i) => <WinnerInfo key={i} el={el} i={i} />);
	const renderResult = () => {
		// Check if the arrays exist before mapping them
		const firstArr = data[side]?.first || [];
		const secondArr = data[side]?.second || [];
		const thirdArr = data[side]?.third || [];
		const fourthArr = data[side]?.fourth || [];
		const fifthArr = data[side]?.fifth || [];

		// Map over the arrays and return the results
		const firstResults = firstArr.map((el: any, i: number) => (
			<WinnerInfo key={el._id} el={el} i={i} n={1} />
		));
		const secondResults = secondArr.map((el: any, i: number) => (
			<WinnerInfo key={el._id} el={el} i={i} n={2} />
		));
		const thirdResults = thirdArr.map((el: any, i: number) => (
			<WinnerInfo key={el._id} el={el} i={i} n={3} />
		));
		const fourthResult = fourthArr.map((el: any, i: number) => (
			<WinnerInfo key={el._id} el={el} i={i} n={4} />
		));
		const fifthResult = fifthArr.map((el: any, i: number) => (
			<WinnerInfo key={el._id} el={el} i={i} n={5} />
		));

		// Return the results as one array
		return [...firstResults, ...secondResults, ...thirdResults, ...fourthResult, ...fifthResult];
	};

	return (
		<section id="winnerInfoWrap">
			<div className="winnerInfo__titles">
				<p className="winner__first">Уровень</p>
				<p className="winner__second">Кошелек</p>
				<p className="winner__rest" style={{ marginLeft: '190px' }}>
          Баланс
				</p>
				<p className="winner__rest" style={{ marginLeft: '190px' }}>
          Сумма покупок
				</p>
			</div>
			{renderResult()}
		</section>
	);
};

export default WinnerInfoWrap;
