import './Loader.css';

const Loader = () => {
	return (
		<div className="modal">
			<div className="loader">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>

			<p>Не перезагружайте страницу! Это займет несколько секунд...</p>
		</div>
	);
};
export default Loader;
