import './Info.css';

import { useEffect } from 'react';

import Footer from '../../components/Footer/Footer';
import HeaderSecond from '../../components/prediction/HeaderSecond/HeaderSecond';
import MainLeader from '../../components/prediction/MainLeader/MainLeader';
import Sidebar from '../../components/prediction/Sidebar/Sidebar';
const Info = () => {
	useEffect(() => {
		document.body.style.overflow = 'hidden';
	}, []);
	return (
		<>
			<section id="predictionPage" className="alternativePage">
				<Sidebar />
				<main className={true ? 'main__grow' : ''}>
					<div className="main__parent">
						<HeaderSecond myKey={'info'} />
						<div className="pg">
							<p>
                ASMAN WORLD - ваш путь к успеху в мире криптовалюты! 🌍🌍
								<br />
								<br />
                Уважаемые друзья представляем вам платформу ASMAN WORLD, где вы можете:
								<br />
                1. расширить свои знания в области инвестиций в сфере криптовалют, <br />
                2. самому стать инвестором и заложить фундамент в свое финансовое будущее,
								<br />
                3. активно участвовать цикле обеспечения ликвидности ASMAN COIN.
								<br />
								<br />
                Что надо сделать чтобы войти в ASMAN WORLD 🌍:
								<br />
                - получить от менеджера команды реферальную ссылку,
								<br />
                - активировать личный кабинет Клиента, подключив к платформе свой крипто-кошелек
                Trust Wallet или MetaMask , <br />
                - активировать свою реферальную ссылку,
								<br />
                - стоимость активации ссылки 110 BUSD
								<br />
                монету BUSD можно закупить на криптобирже Binance 🥉 или другой крупной бирже, где
                торгуется данная монета
								<br />
                Из суммы 110 BUSD 🥉:
								<br />
                - 40$ будет конвертировано в ASMAN COIN, который сразу поступит в ваш крипто кошелёк
                Trust Wallet или MetaMask. Это ваша персональная инвестиция в ASMAN COIN🔥🔥.
								<br />
                - 70$ будут использованы для масштабирования реферальной сети и бонусов от ASMAN
                WORLD.
								<br />
								<br />
								<br />
                В рамках нашей платформы, вы как участник ASMAN WORLD получаете - 2 реферальные
                ссылки, пользуясь которыми вы можете приглашать новых участников в ASMAN WORLD.
								<br />
								<br />
                Ваши возможности в ASMAN WORLD:
								<br />
								<br />
                За каждого приглашенного участника в ASMAN WORLD вы получаете бонус в размере 35$
                (1-й уровень).
								<br />
								<br />
                А теперь по уровням взаимодействия! <br />
                Если ваши приглашенные партнеры проявят инициативу и также будут приглашать новых
                людей, вы будете получать рефералку по 4$ до 5-го уровня партнерства🔥:
								<br />
								<br />
                • 35$ (1-й уровень)
								<br />
                • 4$ (2-й уровень)
								<br />
                • 4$ (3-й уровень)
								<br />
                • 4$ (4-й уровень)
								<br />
                • 4$ (5-й уровень)
								<br />
								<br />
								<br />
                Внимание: система ASMAN WORLD автоматически учитывает товарооборот от первой и
                второй реферальной ссылки до 5 уровня партнерства.
								<br />
								<br />
                И теперь о приятном - о деньгах, бонусах и вознаграждениях!
								<br />
                При достижении товарооборота по бинарному маркетингу (рост подключений по двум
                ссылкам одновременно) вы автоматически получаете бонусы от нашей компании. При
                достижении товарооборота по бинару:
								<br />
								<br />
                • 5000$/5000$, вы получаете бонус в виде 2-х путевок на сумму 1200$
								<br />
								<br />
                • 18000$/18000$ смартфон Apple iPhone 1500$.📱
								<br />
								<br />
                • 80000$/80000$, Авто бонус в размере 17,000$. 🚙
								<br />
								<br />
                • 380000$/380000$, 3х квартира стоимостью 70,000$. 🏢
								<br />
								<br />• 1000 000$/1000 000$ Коттедж 130,000$ 🏠
							</p>
						</div>
					</div>
				</main>
			</section>
			<div className="alternativePage__wrap">
				<Footer />
			</div>
		</>
	);
};

export default Info;
