import './Sidebar.css';

import goblet from 'assets/images/gablet.svg';
import logo from 'assets/images/logo.png';
import present from 'assets/images/present.png';
import rocket from 'assets/images/rocket.svg';
import settings from 'assets/images/setting-2.svg';
import user from 'assets/images/user-octagon.svg';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarContext } from 'utils/SidebarContext';

import Theme from '../Theme/Theme';

const Sidebar = () => {
	const { toggle, toggleFunc } = useContext(SidebarContext);

	const bubbling = (e: { stopPropagation: () => void }) => {
		e.stopPropagation();
	};

	const navActive: any = ({ isActive }: any) => (true ? 'aside__active' : '');

	return (
		<aside onClick={toggleFunc} className={true ? 'aside__grow' : ''}>
			<NavLink className={navActive} onClick={bubbling} to="/">
				<div className="aside__logo-wrap">
					<img className="aside__logo" src={logo} alt="logo" />
					<p>World</p>
				</div>
			</NavLink>
			<div className="aside__wrap">
				<NavLink className={navActive} onClick={bubbling} to="/">
					<img src={rocket} alt="nav" />
					<p>Обменник</p>
				</NavLink>

				<NavLink className={navActive} onClick={bubbling} to="/referals">
					<img src={user} alt="nav" />
					<p>Рефералы</p>
				</NavLink>
				<NavLink className={navActive} onClick={bubbling} to="/info">
					<img src={settings} alt="nav" />
					<p>Информация</p>
				</NavLink>
			</div>
		</aside>
	);
};

export default Sidebar;
