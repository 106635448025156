import './WinnerInfo.css';

import star from 'assets/images/star.svg';
import { randomColor } from 'utils/constants';

const WinnerInfo = (props: any) => {
	return (
		<div className="winnerInfo main__shadow">
			<div className="winner__first">
				<div className="winnerInfo__star">
					<p>{props.n}</p>
				</div>
			</div>

			<div className="winner__second">
				<div className="winnerInfo__name">
					<div className="winner__second-img" style={{ background: '#' + randomColor() }}>
						{props.imgId && <img src={props.imgId} alt="avatar" />}
					</div>
					<p>
						{`${props.el.wallet.substring(0, 6)}...${props.el.wallet.substring(
							props.el.wallet.length - 4,
						)}`}
						{props.el?.username ? `(${props.el.username})` : ''}
					</p>
				</div>
			</div>

			<div className="winner__rest" style={{ marginLeft: '190px' }}>
				<p className="winnerInfo__net">{Number(props.el.balance).toFixed(2)}</p>
			</div>
			<div className="winner__rest" style={{ marginLeft: '190px' }}>
				<p className="winnerInfo__net">{Number(props.el.summary).toFixed(2)}</p>
			</div>
		</div>
	);
};

export default WinnerInfo;
